exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-glossary-block-header-js": () => import("./../../../src/pages/glossary/block-header.js" /* webpackChunkName: "component---src-pages-glossary-block-header-js" */),
  "component---src-pages-glossary-block-producer-js": () => import("./../../../src/pages/glossary/block-producer.js" /* webpackChunkName: "component---src-pages-glossary-block-producer-js" */),
  "component---src-pages-glossary-block-space-js": () => import("./../../../src/pages/glossary/block-space.js" /* webpackChunkName: "component---src-pages-glossary-block-space-js" */),
  "component---src-pages-glossary-blockchain-cluster-js": () => import("./../../../src/pages/glossary/blockchain-cluster.js" /* webpackChunkName: "component---src-pages-glossary-blockchain-cluster-js" */),
  "component---src-pages-glossary-composability-js": () => import("./../../../src/pages/glossary/composability.js" /* webpackChunkName: "component---src-pages-glossary-composability-js" */),
  "component---src-pages-glossary-consensus-algorithm-js": () => import("./../../../src/pages/glossary/consensus-algorithm.js" /* webpackChunkName: "component---src-pages-glossary-consensus-algorithm-js" */),
  "component---src-pages-glossary-consensus-js": () => import("./../../../src/pages/glossary/consensus.js" /* webpackChunkName: "component---src-pages-glossary-consensus-js" */),
  "component---src-pages-glossary-consensus-layer-js": () => import("./../../../src/pages/glossary/consensus-layer.js" /* webpackChunkName: "component---src-pages-glossary-consensus-layer-js" */),
  "component---src-pages-glossary-cosmos-sdk-js": () => import("./../../../src/pages/glossary/cosmos-sdk.js" /* webpackChunkName: "component---src-pages-glossary-cosmos-sdk-js" */),
  "component---src-pages-glossary-cross-chain-interoperability-js": () => import("./../../../src/pages/glossary/cross-chain-interoperability.js" /* webpackChunkName: "component---src-pages-glossary-cross-chain-interoperability-js" */),
  "component---src-pages-glossary-da-js": () => import("./../../../src/pages/glossary/da.js" /* webpackChunkName: "component---src-pages-glossary-da-js" */),
  "component---src-pages-glossary-dac-js": () => import("./../../../src/pages/glossary/dac.js" /* webpackChunkName: "component---src-pages-glossary-dac-js" */),
  "component---src-pages-glossary-das-js": () => import("./../../../src/pages/glossary/das.js" /* webpackChunkName: "component---src-pages-glossary-das-js" */),
  "component---src-pages-glossary-data-availability-committee-js": () => import("./../../../src/pages/glossary/data-availability-committee.js" /* webpackChunkName: "component---src-pages-glossary-data-availability-committee-js" */),
  "component---src-pages-glossary-data-availability-js": () => import("./../../../src/pages/glossary/data-availability.js" /* webpackChunkName: "component---src-pages-glossary-data-availability-js" */),
  "component---src-pages-glossary-data-availability-layer-js": () => import("./../../../src/pages/glossary/data-availability-layer.js" /* webpackChunkName: "component---src-pages-glossary-data-availability-layer-js" */),
  "component---src-pages-glossary-data-availability-sampling-js": () => import("./../../../src/pages/glossary/data-availability-sampling.js" /* webpackChunkName: "component---src-pages-glossary-data-availability-sampling-js" */),
  "component---src-pages-glossary-data-throughput-js": () => import("./../../../src/pages/glossary/data-throughput.js" /* webpackChunkName: "component---src-pages-glossary-data-throughput-js" */),
  "component---src-pages-glossary-data-withholding-attack-js": () => import("./../../../src/pages/glossary/data-withholding-attack.js" /* webpackChunkName: "component---src-pages-glossary-data-withholding-attack-js" */),
  "component---src-pages-glossary-dispute-resolution-js": () => import("./../../../src/pages/glossary/dispute-resolution.js" /* webpackChunkName: "component---src-pages-glossary-dispute-resolution-js" */),
  "component---src-pages-glossary-ee-js": () => import("./../../../src/pages/glossary/ee.js" /* webpackChunkName: "component---src-pages-glossary-ee-js" */),
  "component---src-pages-glossary-execution-environment-js": () => import("./../../../src/pages/glossary/execution-environment.js" /* webpackChunkName: "component---src-pages-glossary-execution-environment-js" */),
  "component---src-pages-glossary-execution-js": () => import("./../../../src/pages/glossary/execution.js" /* webpackChunkName: "component---src-pages-glossary-execution-js" */),
  "component---src-pages-glossary-execution-layer-js": () => import("./../../../src/pages/glossary/execution-layer.js" /* webpackChunkName: "component---src-pages-glossary-execution-layer-js" */),
  "component---src-pages-glossary-fee-market-js": () => import("./../../../src/pages/glossary/fee-market.js" /* webpackChunkName: "component---src-pages-glossary-fee-market-js" */),
  "component---src-pages-glossary-fork-choice-rule-js": () => import("./../../../src/pages/glossary/fork-choice-rule.js" /* webpackChunkName: "component---src-pages-glossary-fork-choice-rule-js" */),
  "component---src-pages-glossary-fork-js": () => import("./../../../src/pages/glossary/fork.js" /* webpackChunkName: "component---src-pages-glossary-fork-js" */),
  "component---src-pages-glossary-full-node-js": () => import("./../../../src/pages/glossary/full-node.js" /* webpackChunkName: "component---src-pages-glossary-full-node-js" */),
  "component---src-pages-glossary-honest-majority-assumption-js": () => import("./../../../src/pages/glossary/honest-majority-assumption.js" /* webpackChunkName: "component---src-pages-glossary-honest-majority-assumption-js" */),
  "component---src-pages-glossary-honest-minority-assumption-js": () => import("./../../../src/pages/glossary/honest-minority-assumption.js" /* webpackChunkName: "component---src-pages-glossary-honest-minority-assumption-js" */),
  "component---src-pages-glossary-inter-cluster-communication-js": () => import("./../../../src/pages/glossary/inter-cluster-communication.js" /* webpackChunkName: "component---src-pages-glossary-inter-cluster-communication-js" */),
  "component---src-pages-glossary-intra-cluster-communication-js": () => import("./../../../src/pages/glossary/intra-cluster-communication.js" /* webpackChunkName: "component---src-pages-glossary-intra-cluster-communication-js" */),
  "component---src-pages-glossary-isr-js": () => import("./../../../src/pages/glossary/isr.js" /* webpackChunkName: "component---src-pages-glossary-isr-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-glossary-light-client-js": () => import("./../../../src/pages/glossary/light-client.js" /* webpackChunkName: "component---src-pages-glossary-light-client-js" */),
  "component---src-pages-glossary-light-node-js": () => import("./../../../src/pages/glossary/light-node.js" /* webpackChunkName: "component---src-pages-glossary-light-node-js" */),
  "component---src-pages-glossary-liveness-js": () => import("./../../../src/pages/glossary/liveness.js" /* webpackChunkName: "component---src-pages-glossary-liveness-js" */),
  "component---src-pages-glossary-modular-blockchain-js": () => import("./../../../src/pages/glossary/modular-blockchain.js" /* webpackChunkName: "component---src-pages-glossary-modular-blockchain-js" */),
  "component---src-pages-glossary-modular-stack-js": () => import("./../../../src/pages/glossary/modular-stack.js" /* webpackChunkName: "component---src-pages-glossary-modular-stack-js" */),
  "component---src-pages-glossary-monolithic-blockchain-js": () => import("./../../../src/pages/glossary/monolithic-blockchain.js" /* webpackChunkName: "component---src-pages-glossary-monolithic-blockchain-js" */),
  "component---src-pages-glossary-namespaced-merkle-tree-js": () => import("./../../../src/pages/glossary/namespaced-merkle-tree.js" /* webpackChunkName: "component---src-pages-glossary-namespaced-merkle-tree-js" */),
  "component---src-pages-glossary-nmt-js": () => import("./../../../src/pages/glossary/nmt.js" /* webpackChunkName: "component---src-pages-glossary-nmt-js" */),
  "component---src-pages-glossary-node-js": () => import("./../../../src/pages/glossary/node.js" /* webpackChunkName: "component---src-pages-glossary-node-js" */),
  "component---src-pages-glossary-off-chain-data-availability-js": () => import("./../../../src/pages/glossary/off-chain-data-availability.js" /* webpackChunkName: "component---src-pages-glossary-off-chain-data-availability-js" */),
  "component---src-pages-glossary-on-chain-data-availability-js": () => import("./../../../src/pages/glossary/on-chain-data-availability.js" /* webpackChunkName: "component---src-pages-glossary-on-chain-data-availability-js" */),
  "component---src-pages-glossary-optimistic-rollup-js": () => import("./../../../src/pages/glossary/optimistic-rollup.js" /* webpackChunkName: "component---src-pages-glossary-optimistic-rollup-js" */),
  "component---src-pages-glossary-oru-js": () => import("./../../../src/pages/glossary/oru.js" /* webpackChunkName: "component---src-pages-glossary-oru-js" */),
  "component---src-pages-glossary-pay-for-data-js": () => import("./../../../src/pages/glossary/pay-for-data.js" /* webpackChunkName: "component---src-pages-glossary-pay-for-data-js" */),
  "component---src-pages-glossary-peer-to-peer-network-js": () => import("./../../../src/pages/glossary/peer-to-peer-network.js" /* webpackChunkName: "component---src-pages-glossary-peer-to-peer-network-js" */),
  "component---src-pages-glossary-pfb-js": () => import("./../../../src/pages/glossary/pfb.js" /* webpackChunkName: "component---src-pages-glossary-pfb-js" */),
  "component---src-pages-glossary-proof-of-stake-js": () => import("./../../../src/pages/glossary/proof-of-stake.js" /* webpackChunkName: "component---src-pages-glossary-proof-of-stake-js" */),
  "component---src-pages-glossary-qgb-js": () => import("./../../../src/pages/glossary/qgb.js" /* webpackChunkName: "component---src-pages-glossary-qgb-js" */),
  "component---src-pages-glossary-rollup-js": () => import("./../../../src/pages/glossary/rollup.js" /* webpackChunkName: "component---src-pages-glossary-rollup-js" */),
  "component---src-pages-glossary-safety-js": () => import("./../../../src/pages/glossary/safety.js" /* webpackChunkName: "component---src-pages-glossary-safety-js" */),
  "component---src-pages-glossary-scalability-js": () => import("./../../../src/pages/glossary/scalability.js" /* webpackChunkName: "component---src-pages-glossary-scalability-js" */),
  "component---src-pages-glossary-sequencer-js": () => import("./../../../src/pages/glossary/sequencer.js" /* webpackChunkName: "component---src-pages-glossary-sequencer-js" */),
  "component---src-pages-glossary-settlement-js": () => import("./../../../src/pages/glossary/settlement.js" /* webpackChunkName: "component---src-pages-glossary-settlement-js" */),
  "component---src-pages-glossary-settlement-layer-js": () => import("./../../../src/pages/glossary/settlement-layer.js" /* webpackChunkName: "component---src-pages-glossary-settlement-layer-js" */),
  "component---src-pages-glossary-sharding-js": () => import("./../../../src/pages/glossary/sharding.js" /* webpackChunkName: "component---src-pages-glossary-sharding-js" */),
  "component---src-pages-glossary-shared-security-js": () => import("./../../../src/pages/glossary/shared-security.js" /* webpackChunkName: "component---src-pages-glossary-shared-security-js" */),
  "component---src-pages-glossary-slashing-js": () => import("./../../../src/pages/glossary/slashing.js" /* webpackChunkName: "component---src-pages-glossary-slashing-js" */),
  "component---src-pages-glossary-smart-contract-js": () => import("./../../../src/pages/glossary/smart-contract.js" /* webpackChunkName: "component---src-pages-glossary-smart-contract-js" */),
  "component---src-pages-glossary-social-consensus-js": () => import("./../../../src/pages/glossary/social-consensus.js" /* webpackChunkName: "component---src-pages-glossary-social-consensus-js" */),
  "component---src-pages-glossary-sovereign-application-js": () => import("./../../../src/pages/glossary/sovereign-application.js" /* webpackChunkName: "component---src-pages-glossary-sovereign-application-js" */),
  "component---src-pages-glossary-sovereign-blockchain-js": () => import("./../../../src/pages/glossary/sovereign-blockchain.js" /* webpackChunkName: "component---src-pages-glossary-sovereign-blockchain-js" */),
  "component---src-pages-glossary-sovereign-rollup-js": () => import("./../../../src/pages/glossary/sovereign-rollup.js" /* webpackChunkName: "component---src-pages-glossary-sovereign-rollup-js" */),
  "component---src-pages-glossary-state-transition-fraud-proof-js": () => import("./../../../src/pages/glossary/state-transition-fraud-proof.js" /* webpackChunkName: "component---src-pages-glossary-state-transition-fraud-proof-js" */),
  "component---src-pages-glossary-synchrony-assumption-js": () => import("./../../../src/pages/glossary/synchrony-assumption.js" /* webpackChunkName: "component---src-pages-glossary-synchrony-assumption-js" */),
  "component---src-pages-glossary-the-data-availability-problem-js": () => import("./../../../src/pages/glossary/the-data-availability-problem.js" /* webpackChunkName: "component---src-pages-glossary-the-data-availability-problem-js" */),
  "component---src-pages-glossary-throughput-js": () => import("./../../../src/pages/glossary/throughput.js" /* webpackChunkName: "component---src-pages-glossary-throughput-js" */),
  "component---src-pages-glossary-transaction-throughput-js": () => import("./../../../src/pages/glossary/transaction-throughput.js" /* webpackChunkName: "component---src-pages-glossary-transaction-throughput-js" */),
  "component---src-pages-glossary-trust-minimized-bridge-js": () => import("./../../../src/pages/glossary/trust-minimized-bridge.js" /* webpackChunkName: "component---src-pages-glossary-trust-minimized-bridge-js" */),
  "component---src-pages-glossary-trusted-bridge-js": () => import("./../../../src/pages/glossary/trusted-bridge.js" /* webpackChunkName: "component---src-pages-glossary-trusted-bridge-js" */),
  "component---src-pages-glossary-validator-js": () => import("./../../../src/pages/glossary/validator.js" /* webpackChunkName: "component---src-pages-glossary-validator-js" */),
  "component---src-pages-glossary-validator-set-js": () => import("./../../../src/pages/glossary/validator-set.js" /* webpackChunkName: "component---src-pages-glossary-validator-set-js" */),
  "component---src-pages-glossary-validity-proof-js": () => import("./../../../src/pages/glossary/validity-proof.js" /* webpackChunkName: "component---src-pages-glossary-validity-proof-js" */),
  "component---src-pages-glossary-validium-js": () => import("./../../../src/pages/glossary/validium.js" /* webpackChunkName: "component---src-pages-glossary-validium-js" */),
  "component---src-pages-glossary-volition-js": () => import("./../../../src/pages/glossary/volition.js" /* webpackChunkName: "component---src-pages-glossary-volition-js" */),
  "component---src-pages-glossary-zk-rollup-js": () => import("./../../../src/pages/glossary/zk-rollup.js" /* webpackChunkName: "component---src-pages-glossary-zk-rollup-js" */),
  "component---src-pages-glossary-zkr-js": () => import("./../../../src/pages/glossary/zkr.js" /* webpackChunkName: "component---src-pages-glossary-zkr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-past-events-js": () => import("./../../../src/pages/past-events.js" /* webpackChunkName: "component---src-pages-past-events-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-run-a-light-node-js": () => import("./../../../src/pages/run-a-light-node.js" /* webpackChunkName: "component---src-pages-run-a-light-node-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-what-is-celestia-js": () => import("./../../../src/pages/what-is-celestia.js" /* webpackChunkName: "component---src-pages-what-is-celestia-js" */),
  "component---src-pages-what-is-tia-js": () => import("./../../../src/pages/what-is-tia.js" /* webpackChunkName: "component---src-pages-what-is-tia-js" */)
}

